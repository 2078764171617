@import "https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap";
:root {
  --brand-primary: #6d28d9;
  --brand-secondary: #4c1d95;
  --brand-accent: #10b981;
  --surface-dark: #0a0a0f;
  --surface-card: #1c1c23b3;
  --surface-overlay: #101016fa;
  --gradient-bg: linear-gradient(130deg, #13131a 0%, #0a0a0f 100%);
  --gradient-card: linear-gradient(160deg, #6d28d91a 0%, #6d28d900 100%);
  --gradient-hover: linear-gradient(45deg, #6d28d933 0%, #10b98133 100%);
  --text-primary: #f8fafc;
  --text-secondary: #94a3b8;
  --text-muted: #64748b;
  --transition-smooth: cubic-bezier(.4, 0, .2, 1);
  --transition-bounce: cubic-bezier(.68, -.6, .32, 1.6);
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  background: var(--gradient-bg);
  color: var(--text-primary);
  min-height: 100vh;
  font-family: Plus Jakarta Sans, sans-serif;
  line-height: 1.5;
  overflow-x: hidden;
}

header {
  background: var(--surface-overlay);
  -webkit-backdrop-filter: blur(20px);
  z-index: 1000;
  border-bottom: 1px solid #ffffff0d;
  width: 100%;
  padding: 1.5rem 2rem;
  position: fixed;
  top: 0;
  transform: translateZ(0);
}

header h1 {
  letter-spacing: -.02em;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #fff, #94a3b8) text;
  margin-bottom: .5rem;
  font-size: 2.5rem;
  font-weight: 700;
}

header p {
  color: var(--text-secondary);
  max-width: 600px;
  font-size: 1.125rem;
  font-weight: 500;
}

main {
  max-width: 2000px;
  margin-top: 6rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.game-selection {
  margin: 3rem auto;
}

.game-selection h2 {
  letter-spacing: -.02em;
  text-align: center;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #fff, #94a3b8) text;
  margin-bottom: 2.5rem;
  font-size: 2.25rem;
  font-weight: 700;
}

.game-grid {
  perspective: 1000px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
  display: grid;
}

.game {
  background: var(--surface-card);
  transition: all .5s var(--transition-smooth);
  -webkit-backdrop-filter: blur(10px);
  transform-style: preserve-3d;
  will-change: transform;
  border: 1px solid #ffffff0d;
  border-radius: 1.25rem;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.game:before {
  content: "";
  background: var(--gradient-card);
  opacity: 0;
  transition: opacity .5s var(--transition-smooth);
  z-index: 1;
  position: absolute;
  inset: 0;
}

.game img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .7s var(--transition-smooth);
  transform-origin: center;
  filter: brightness(.95);
  position: absolute;
  top: 0;
  left: 0;
}

.game p {
  color: var(--text-primary);
  transition: transform .5s var(--transition-bounce);
  z-index: 2;
  background: linear-gradient(#0000, #0a0a0ff2);
  padding: 2rem 1.5rem;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.game:hover {
  transform: translateY(-10px)rotateX(2deg)rotateY(-2deg);
  box-shadow: 0 25px 50px -12px #00000080, 0 0 50px -12px #6d28d933;
}

.game:hover:before {
  opacity: 1;
}

.game:hover img {
  filter: brightness(1.1);
  transform: scale(1.1);
}

.game:hover p {
  transform: translateY(0);
}

#ad-overlay {
  -webkit-backdrop-filter: blur(30px);
  z-index: 2000;
  background: #0a0a0ffa;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
  position: fixed;
  inset: 0;
}

#ad-player {
  background: var(--surface-card);
  border-radius: 1.5rem;
  width: 90%;
  max-width: 1200px;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 1px #ffffff0d, 0 50px 100px -20px #000c, 0 0 60px -15px #6d28d966;
}

#ad-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.countdown-text {
  color: var(--text-primary);
  -webkit-backdrop-filter: blur(10px);
  z-index: 2;
  background: #0a0a0fe6;
  border: 1px solid #ffffff0d;
  border-radius: 3rem;
  padding: .75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  box-shadow: 0 10px 30px -5px #0000004d;
}

#launch-screen {
  background: var(--gradient-bg);
  z-index: 1500;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  display: flex;
  position: fixed;
  inset: 0;
}

.loading-text {
  letter-spacing: -.02em;
  -webkit-text-fill-color: transparent;
  animation: pulse 2s var(--transition-smooth) infinite;
  background: linear-gradient(to right, #fff, #94a3b8) text;
  font-size: 2rem;
  font-weight: 700;
}

.loading-spinner {
  border: 4px solid #6d28d91a;
  border-top-color: var(--brand-primary);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: 1s linear infinite spin;
  box-shadow: 0 0 30px -5px #6d28d980;
}

#game-stream {
  background: var(--surface-dark);
  z-index: 1800;
  position: fixed;
  inset: 0;
}

#stream-container {
  background: var(--surface-dark);
  width: 100%;
  height: 100%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.hidden {
  display: none !important;
}

@media (width <= 1440px) {
  .game-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (width <= 768px) {
  header {
    padding: 1.25rem;
  }

  header h1 {
    font-size: 2rem;
  }

  header p {
    font-size: 1rem;
  }

  main {
    padding: 1rem;
  }

  .game-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 1.5rem;
  }

  .game-selection h2 {
    margin-bottom: 2rem;
    font-size: 1.75rem;
  }

  #ad-overlay {
    padding: 1rem;
  }

  .loading-text {
    font-size: 1.5rem;
  }

  .loading-spinner {
    width: 3rem;
    height: 3rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@media print {
  .game-grid {
    display: block;
  }

  .game {
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 1rem;
  }
}

#queue-screen {
  background: var(--gradient-bg);
  z-index: 1500;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.queue-content {
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.queue-position {
  color: var(--text-secondary);
  font-size: 1.25rem;
  font-weight: 500;
}
/*# sourceMappingURL=index.8c288562.css.map */
