/* Premium Cloud Gaming Platform Styling */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');

:root {
    /* Brand Colors */
    --brand-primary: #6D28D9;
    --brand-secondary: #4C1D95;
    --brand-accent: #10B981;

    /* UI Colors */
    --surface-dark: #0A0A0F;
    --surface-card: rgba(28, 28, 35, 0.7);
    --surface-overlay: rgba(16, 16, 22, 0.98);

    /* Gradients */
    --gradient-bg: linear-gradient(130deg, #13131A 0%, #0A0A0F 100%);
    --gradient-card: linear-gradient(160deg, rgba(109, 40, 217, 0.1) 0%, rgba(109, 40, 217, 0) 100%);
    --gradient-hover: linear-gradient(45deg, rgba(109, 40, 217, 0.2) 0%, rgba(16, 185, 129, 0.2) 100%);

    /* Text Colors */
    --text-primary: #F8FAFC;
    --text-secondary: #94A3B8;
    --text-muted: #64748B;

    /* Animations */
    --transition-smooth: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-bounce: cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

/* Modern Reset with Improved Defaults */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    background: var(--gradient-bg);
    color: var(--text-primary);
    min-height: 100vh;
    line-height: 1.5;
    overflow-x: hidden;
}

/* Premium Header Styling */
header {
    background: var(--surface-overlay);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding: 1.5rem 2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    transform: translateZ(0);
}

header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    background: linear-gradient(to right, #fff, #94A3B8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.5rem;
}

header p {
    font-size: 1.125rem;
    color: var(--text-secondary);
    max-width: 600px;
    font-weight: 500;
}

/* Main Content Area */
main {
    margin-top: 6rem;
    padding: 2rem;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
}

/* Game Selection Grid */
.game-selection {
    margin: 3rem auto;
}

.game-selection h2 {
    font-size: 2.25rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    margin-bottom: 2.5rem;
    text-align: center;
    background: linear-gradient(to right, #fff, #94A3B8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.game-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem;
    perspective: 1000px;
}

/* Premium Game Card Styling */
.game {
    position: relative;
    border-radius: 1.25rem;
    overflow: hidden;
    background: var(--surface-card);
    transition: all 0.5s var(--transition-smooth);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    transform-style: preserve-3d;
    will-change: transform;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
}

.game::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--gradient-card);
    opacity: 0;
    transition: opacity 0.5s var(--transition-smooth);
    z-index: 1;
}

.game img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.7s var(--transition-smooth);
    transform-origin: center;
    filter: brightness(0.95);
}

.game p {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem 1.5rem;
    background: linear-gradient(transparent, rgba(10, 10, 15, 0.95));
    color: var(--text-primary);
    font-weight: 600;
    transform: translateY(100%);
    transition: transform 0.5s var(--transition-bounce);
    z-index: 2;
}

/* Premium Hover Effects */
.game:hover {
    transform: translateY(-10px) rotateX(2deg) rotateY(-2deg);
    box-shadow:
        0 25px 50px -12px rgba(0, 0, 0, 0.5),
        0 0 50px -12px rgba(109, 40, 217, 0.2);
}

.game:hover::before {
    opacity: 1;
}

.game:hover img {
    transform: scale(1.1);
    filter: brightness(1.1);
}

.game:hover p {
    transform: translateY(0);
}

/* Ad Overlay Premium Styling */
#ad-overlay {
    position: fixed;
    inset: 0;
    background: rgba(10, 10, 15, 0.98);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    padding: 2rem;
}

#ad-player {
    position: relative;
    width: 90%;
    max-width: 1200px;
    background: var(--surface-card);
    border-radius: 1.5rem;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
    box-shadow:
        0 0 0 1px rgba(255, 255, 255, 0.05),
        0 50px 100px -20px rgba(0, 0, 0, 0.8),
        0 0 60px -15px rgba(109, 40, 217, 0.4);
}

#ad-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.countdown-text {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: rgba(10, 10, 15, 0.9);
    padding: 0.75rem 1.5rem;
    border-radius: 3rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-primary);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3);
    z-index: 2;
}

/* Premium Loading Screen */
#launch-screen {
    position: fixed;
    inset: 0;
    background: var(--gradient-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    z-index: 1500;
}

.loading-text {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    background: linear-gradient(to right, #fff, #94A3B8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: pulse 2s var(--transition-smooth) infinite;
}

.loading-spinner {
    width: 4rem;
    height: 4rem;
    border: 4px solid rgba(109, 40, 217, 0.1);
    border-top-color: var(--brand-primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    box-shadow: 0 0 30px -5px rgba(109, 40, 217, 0.5);
}

/* Game Stream Container */
#game-stream {
    position: fixed;
    inset: 0;
    background: var(--surface-dark);
    z-index: 1800;
}

#stream-container {
    width: 100%;
    height: 100%;
    background: var(--surface-dark);
}

/* Animations */
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* Utility Classes */
.hidden {
    display: none !important;
}

/* Premium Responsive Design */
@media (max-width: 1440px) {
    .game-grid {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}

@media (max-width: 768px) {
    header {
        padding: 1.25rem;
    }

    header h1 {
        font-size: 2rem;
    }

    header p {
        font-size: 1rem;
    }

    main {
        padding: 1rem;
    }

    .game-grid {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        gap: 1.5rem;
    }

    .game-selection h2 {
        font-size: 1.75rem;
        margin-bottom: 2rem;
    }

    #ad-overlay {
        padding: 1rem;
    }

    .loading-text {
        font-size: 1.5rem;
    }

    .loading-spinner {
        width: 3rem;
        height: 3rem;
    }
}

/* High-Performance Optimizations */
@media (prefers-reduced-motion: reduce) {

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/* Print Styles */
@media print {
    .game-grid {
        display: block;
    }

    .game {
        break-inside: avoid;
        page-break-inside: avoid;
        margin-bottom: 1rem;
    }
}

#queue-screen {
    position: fixed;
    inset: 0;
    background: var(--gradient-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
}

.queue-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.queue-position {
    font-size: 1.25rem;
    color: var(--text-secondary);
    font-weight: 500;
}